import SendQueue from './SendQueue';

const API_URL = 'https://ua.harryonline.net/api/batch';

const website =
  window.location.hostname === 'localhost'
    ? 'fe61cf61-0e04-43aa-9e4b-a195b7b0d3b4'
    : '52544fdb-c765-422b-9739-11fea5716ebe';

const {
  screen: { width, height },
  navigator: { language },
  location,
  document,
} = window;
const { hostname, origin } = location;
const { referrer } = document;
const screen = `${width}x${height}`;

const pageInfo = { title: '', url: '/' };

let cache;

/* Helper functions */

const getPayload = () => ({
  website,
  screen,
  language,
  hostname,
  ...pageInfo,
  referrer: referrer.startsWith(origin) ? '' : referrer,
  timestamp: Math.floor(Date.now() / 1000),
});

const batchSend = async queue => {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (typeof cache !== 'undefined') {
    headers['x-umami-cache'] = cache;
  }

  try {
    const res = await fetch(API_URL, {
      method: 'POST',
      body: JSON.stringify(queue),
      headers,
      credentials: 'omit',
    });
    const data = await res.json();

    if (data) {
      cache = data.cache; // eslint-disable-line prefer-destructuring
    }
  } catch (e) {
    /* empty */
  }
};

const queueSend = new SendQueue(batchSend);

const send = (payload, type = 'event') => {
  queueSend.send({ payload, type });
  if (!payload.name) {
    // Send immediately on page view events
    queueSend.flush();
  }
};

const umami = (name, data) => {
  switch (typeof name) {
    case 'string':
      return send({
        ...getPayload(),
        name,
        data,
        website,
      });
    case 'function': {
      const newPayload = name(getPayload());
      Object.keys(pageInfo).forEach(key => {
        pageInfo[key] = newPayload[key];
      });
      return send(newPayload);
    }
    default:
      return send(getPayload());
  }
};

/* Start */
umami();

export default umami;
