// From: https://redux.js.org/docs/advanced/Middleware.html

import { LOCATION_CHANGE } from 'connected-react-router';
import { MAP_CHANGEDTYPE } from 'containers/MapPage/constants';
import { CHANGE_SETTING } from 'containers/SettingsProvider/constants';
import {
  EDIT_CREATE,
  EDIT_EDIT,
  EDIT_ROUTING,
  EDIT_SAVE,
  EDIT_CLEAR,
} from 'containers/EditService/constants';
import { GPX_UPLOAD } from 'containers/ImportGpx/constants';
import { GEO_SEARCH } from 'containers/GeoSearch/constants';
import { UI_TOGGLE } from 'containers/UiControl/constants';
import Track from 'utils/analytics/Track';
import trackLocation from './trackLocation';
import trackUiToggle from './trackUiToggle';

let routingCount = 0;

const middleware = store => next => action => {
  const state = store.getState();
  switch (action.type) {
    case LOCATION_CHANGE:
      trackLocation(action.payload.location);
      break;
    case CHANGE_SETTING:
      Track.event({
        category: 'Setting',
        action: action.setting,
        label: action.value.toString(),
      });
      break;
    case GEO_SEARCH:
      Track.event({
        category: 'Map',
        action: 'Search',
      });
      break;
    case GPX_UPLOAD:
      Track.event({
        category: 'Edit',
        action: 'Import',
        label: 'GPX',
      });
      break;
    case MAP_CHANGEDTYPE:
      Track.event({
        category: 'Map',
        action: 'Type',
        label: action.mapType,
      });
      break;
    case EDIT_CLEAR:
      Track.event({
        category: 'Edit',
        action: 'Clear route',
        value: routingCount,
      });
      routingCount = 0;
      break;
    case EDIT_SAVE:
      Track.event({
        category: 'Edit',
        action: 'Save route',
        value: routingCount,
      });
      routingCount = 0;
      break;
    case EDIT_CREATE:
      Track.event({
        category: 'Edit',
        action: 'Create new',
      });
      break;
    case EDIT_EDIT:
      Track.event({
        category: 'Edit',
        action: 'Edit existing',
        label: action.route.isOwn ? 'own' : 'others',
      });
      break;
    case EDIT_ROUTING:
      routingCount += 1;
      break;
    case UI_TOGGLE:
      trackUiToggle(state.get('uiControl').toJS(), action);
      break;
    default:
    // Not tracked
  }
  return next(action);
};

export default middleware;
