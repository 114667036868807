import { capitalize } from 'lodash';
import ReactGA from 'react-ga4';
import parseSearch from 'utils/parseSearch';
import umami from './umami';

const Track = {
  ...ReactGA,
  event,
};

function gaLog(func) {
  return args => {
    console.log('GA', func, args); // eslint-disable-line no-console
    ReactGA[func](args);
  };
}

function event(options) {
  const { category, action, label, value } = options;
  const name = [category, action].map(capitalize).join(' ');
  umami(name, { label, value });
  ReactGA.event(options);
}

const { track } = parseSearch();
if (track) {
  track.split(',').forEach(func => {
    Track[func] = gaLog(func);
  });
}

export default Track;
