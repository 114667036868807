/**
 *
 * AdSpace
 *
 */

import React, { useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useCurrentUser } from 'containers/User';
import { green500, greenA100 } from 'styles/colors';
import addScript from 'utils/addScript';
import trackBlocked from './trackBlocked';
import Premium from './Premium';

let scriptLoaded = false;

const Wrapper = styled.div`
  &.shaded {
    background-color: ${greenA100};
  }
  justify-content: center;
  margin-top: 5px;
  min-height: 50px;
  .premium-banner {
    height: auto;
    margin: 2px;
    border 2px solid ${green500};
    a {
      font-weight: 600;
    }
  }
  .ad-responsive-2 {
    display: block;
    height: 50px;
  }
  @media (min-height: 720px) {
    min-height: 100px;
    .ad-responsive-2 {
      height: 100px;
    }
  }
  @media (orientation: portrait) {
    &.loaded .premium-banner {
      display: none;
    }
    &:not(.loaded) .ad-responsive-2 {
      display: none;
    }
  }
  @media (orientation: landscape) {
    margin-top: 0px;
    margin-left: 10px;
    width: 120px;
    .ad-responsive-2 {
      height: auto;
    }
    @media (min-width: 1200px) {
      width: 160px;
    }
    @media (min-width: 1500px) {
      width: 300px;
    }
  }
  @media print {
    display: none;
  }
`;

function AdSpace() {
  const user = useCurrentUser();
  const { hostname, pathname } = window.location;
  const local = hostname === 'localhost' || hostname.startsWith('192.168.0');
  const userLoading = user.loading !== false;
  const wasPremium = user.pmDate && new Date(user.pmDate) > Date.now();
  const ref = useRef();

  const hideAds = user.su || user.pm || pathname.startsWith('/inschrijven');
  useEffect(
    () => {
      if (hideAds || local || userLoading || scriptLoaded) {
        return;
      }
      addScript({
        src:
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5250208448286270',
        crossorigin: 'anonymous',
      });
      trackBlocked();
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      scriptLoaded = true;
      ref.current.classList.add('loaded');
    },
    [hideAds, userLoading],
  );

  if (userLoading && wasPremium) {
    return null;
  }
  if (hideAds && !userLoading) {
    return null;
  }
  return (
    <Wrapper ref={ref} className={local ? 'shaded' : ''}>
      <Premium />
      <ins
        className="adsbygoogle ad-responsive-2"
        data-ad-client="ca-pub-5250208448286270"
        data-ad-slot="9514948568"
        data-full-width-responsive="true"
      />
    </Wrapper>
  );
}

export default AdSpace;
