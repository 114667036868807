class SendQueue {
  /**
   * @param {Function} batchSend - Function to send a batch of data.
   * @param {number} [delay=10000] - Delay in milliseconds before sending the batch.
   */
  constructor(batchSend, delay = 10000) {
    this.batchSend = batchSend;
    this.queue = [];
    this.handle = null;
    this.delay = delay;
    window.addEventListener('beforeunload', this.flush);
  }

  /**
   * Flushes the queue by sending the batch of data.
   */
  flush = () => {
    if (!this.queue.length) {
      return;
    }
    const len = this.queue.length;
    this.batchSend(this.queue)
      .then(() => {
        this.queue.splice(0, len);
        if (this.handle) {
          clearTimeout(this.handle);
          this.handle = null;
        }
      })
      .catch(error => {
        console.error('Failed to send analytics data', error); // eslint-disable-line no-console
      });
  };

  /**
   * Adds data to the queue and schedules a batch send.
   * @param {any} data - Data to be sent.
   */
  send = data => {
    this.queue.push(data);
    if (!this.handle) {
      this.handle = setTimeout(this.flush, this.delay);
    }
  };
}

export default SendQueue;
