/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import 'utils/Sentry';
import MapPage from 'containers/MapPage/Loadable';
import KmVretersPage from 'containers/KmVretersPage';
import VirtualPage from 'containers/VirtualPage';
import ReservationsPage from 'containers/ReservationsPage';
import Header from 'components/Header';
import Debug from 'components/Debug';
import AdSpace from 'components/AdSpace';
import MetaTags from 'components/MetaTags';
import { KmMarkerStyles } from 'components/KmMarker';
import User from 'containers/User';
import messages from 'containers/MapPage/messages';
import AlertService from 'containers/AlertService';
import Dialog from 'containers/Dialog';
import 'utils/fontawesome';
import { GlobalStyles, EditIconsStyles } from 'styles';
import {
  PATH_CLUB,
  PATH_HOME,
  PATH_LOCATION,
  PATH_ROUTE,
  PATH_SIGNIN,
  PATH_DEBUG,
  EMBEDDED,
  IFRAME,
  PATH_KMVRETERS,
  PATH_VIRTUAL,
  PATH_RESERVATIONS,
  PATH_MANAGE,
} from './constants';
import HashModals from './HashModals';
import { version } from '../../../package.json';
import IframeCover from './IframeCover';
import Manage from '../../components/Manage';
import umami from '../../utils/analytics/umami';

console.log('RunnerMaps', version); // eslint-disable-line no-console
export default function App() {
  const history = useHistory();
  const { pathname, search, hash } = useLocation();
  const fullPath = `${pathname}${search}${hash}`;
  const match = search.match(/(route|club)=(\d+)/);
  if (match) {
    history.replace(`/${match[1]}/${match[2]}`);
  }
  if (hash[1] === '/') {
    history.replace(hash.slice(1));
  }

  useEffect(
    () => {
      umami(p => ({ ...p, url: fullPath, title: document.title }));
    },
    [fullPath],
  );

  return (
    <AlertService>
      <GlobalStyles />
      <EditIconsStyles />
      <KmMarkerStyles />
      <User>
        <MetaTags tags={messages} />
        <div className="d-flex flex-column flex-grow-1 overflow-container align-items-stretch">
          {!EMBEDDED && <Header />}
          <Switch>
            <Route exact path={PATH_LOCATION} component={MapPage} />
            <Route exact path={PATH_CLUB} component={MapPage} />
            <Route exact path={PATH_ROUTE} component={MapPage} />
            <Route exact path={PATH_HOME} component={MapPage} />
            <Route exact path={PATH_SIGNIN} component={MapPage} />
            <Route exact path={PATH_DEBUG} component={Debug} />
            <Route path={PATH_MANAGE} component={Manage} />
            <Route exact path={PATH_KMVRETERS} component={KmVretersPage} />
            <Route exact path={PATH_VIRTUAL} component={VirtualPage} />
            <Route exact path="/jubileum" component={VirtualPage} />
            <Route
              exact
              path={PATH_RESERVATIONS}
              component={ReservationsPage}
            />
            <Redirect from="/kmvreters" to="/kmvreters/ranking" />
            <Redirect
              from="/kmvreters/ranking/club/:id"
              to="/kmvreters/clubs/:id"
            />
            <Route render={() => <Redirect to={`${PATH_HOME}${hash}`} />} />
          </Switch>
        </div>
        {!IFRAME && <AdSpace />}
        <HashModals />
        <IframeCover />
        <Dialog />
      </User>
    </AlertService>
  );
}
